import React from 'react';
import styled, { keyframes } from 'styled-components';

// Import partner logos
import partnerLogo1 from '../assets/plogos/ps.png';
import partnerLogo2 from '../assets/plogos/ds.png';
import partnerLogo3 from '../assets/plogos/dv.png';
import partnerLogo4 from '../assets/plogos/dt.png';
import partnerLogo6 from '../assets/plogos/cmc.png';
import partnerLogo7 from '../assets/plogos/coingecko.webp';
import partnerLogo8 from '../assets/plogos/solscan.webp';

// Import healthcare organization logos
import iffgdLogo from '../assets/plogos/iffgd.png';
import fbgLogo from '../assets/plogos/FBG.png';
import esnmLogo from '../assets/plogos/ESNM.png';
import anmaLogo from '../assets/plogos/ANMA.png';
import gesaLogo from '../assets/plogos/GESA.png';
import sagesLogo from '../assets/plogos/SAGES.png';

const moveAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const PartnershipContainer = styled.section`
  padding: 4rem 1rem;
  overflow: hidden;
  position: relative;

  @media (min-width: 768px) {
    padding: 5rem 1.5rem;
  }

  @media (min-width: 992px) {
    padding: 6rem 2rem;
  }
`;

const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 3rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
               0 0 20px rgba(76, 140, 47, 0.4),
               4px 4px 0 #8b4513;
  -webkit-text-stroke: 2px #4c8c2f;
`;

const SubTitle = styled.h3`
  font-size: 1.5rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 1.5rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  
  span {
    color: #ffcc00;
    text-shadow: 0 0 5px rgba(255, 204, 0, 0.8);
  }
`;

// Moving strip components - using the working version's structure
const StripWrapper = styled.div`
  margin: 2rem 0;
  padding: 1rem 0;
  background: rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const LogoStrip = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  animation: ${moveAnimation} ${props => props.$duration || '30s'} linear infinite;
  will-change: transform;

  &:hover {
    animation-play-state: paused;
  }
`;

const PartnerLogo = styled.img`
  height: 35px;
  width: auto;
  margin: 0 30px;
  object-fit: contain;
  filter: grayscale(100%);
  transition: filter 0.3s ease;

  &:hover {
    filter: grayscale(0%);
  }

  @media (max-width: 768px) {
    height: 25px;
    margin: 0 20px;
  }
`;

const HealthOrgLogo = styled.a`
  display: flex;
  align-items: center;
  margin: 0 30px;
  text-decoration: none;
  
  img {
    height: 45px;
    width: auto;
    object-fit: contain;
   
    transition: filter 0.3s ease;
  }

  &:hover img {
    filter: grayscale(0%);
  }

  @media (max-width: 768px) {
    margin: 0 20px;
    
    img {
      height: 35px;
    }
  }
`;
// Data arrays
const partnerLogos = [
  { src: partnerLogo1, alt: "PinkSale" },
  { src: partnerLogo2, alt: "DexScreener" },
  { src: partnerLogo3, alt: "DexView" },
  { src: partnerLogo4, alt: "DexTools" },
  { src: partnerLogo6, alt: "CoinMarketCap" },
  { src: partnerLogo7, alt: "CoinGecko" },
  { src: partnerLogo8, alt: "Solscan" },
];

const healthOrganizations = [
  {
    name: "IFFGD",
    logo: iffgdLogo,
    url: "https://iffgd.org"
  },
  {
    name: "FBG",
    logo: fbgLogo,
    url: "https://fbg.org.br"
  },
  {
    name: "ESNM",
    logo: esnmLogo,
    url: "https://www.esnm.eu"
  },
  {
    name: "ANMA",
    logo: anmaLogo,
    url: "https://www.e-anma.org/"
  },
  {
    name: "GESA",
    logo: gesaLogo,
    url: "https://www.gesa.org.au"
  },
  {
    name: "SAGES",
    logo: sagesLogo,
    url: "https://www.sages.co.za"
  }
];

// Strip Components
const PartnersStrip = () => {
  const duplicatedPartners = [...partnerLogos, ...partnerLogos];

  return (
    <StripWrapper>
      <LogoStrip $duration="30s">
        {duplicatedPartners.map((partner, index) => (
          <PartnerLogo
            key={`partner-${index}`}
            src={partner.src}
            alt={partner.alt}
            loading="lazy"
          />
        ))}
      </LogoStrip>
    </StripWrapper>
  );
};

const HealthOrgsStrip = () => {
  const duplicatedOrgs = [...healthOrganizations, ...healthOrganizations];

  return (
    <StripWrapper>
      <LogoStrip $duration="35s">
        {duplicatedOrgs.map((org, index) => (
          <HealthOrgLogo
            key={`org-${index}`}
            href={org.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={org.logo} alt={org.name} loading="lazy" />
          </HealthOrgLogo>
        ))}
      </LogoStrip>
    </StripWrapper>
  );
};

// Main Component
const PartnershipSection = () => {
  return (
    <PartnershipContainer id="partnerships">
      <Title>FART-NERSHIPS</Title>

      <div style={{ marginBottom: "4rem" }}>
        <SubTitle>
          Our <span>Partners</span>
        </SubTitle>
        <PartnersStrip />
      </div>

      <div>
        <SubTitle>
          Supporting <span>Healthcare Organizations</span>
        </SubTitle>
        <HealthOrgsStrip />
      </div>
    </PartnershipContainer>
  );
};

export default PartnershipSection;