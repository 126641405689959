import React from 'react';
import styled, { keyframes } from 'styled-components';
import dexscreener from "../../assets/header/dexscreener.webp";
import coinmarketcap from "../../assets/header/coinmarketcap.webp";
import dexview from "../../assets/header/coingeco.png";
import dexstools from "../../assets/header/dex.webp";
import xlogo from "../../assets/logo-white.png";
import tlogo from "../../assets/header/telegram.webp";
import HeroContent from './HeroContent';
import LogoOrbitDisplay from './LogoOrbitDisplay';

const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


const HeroContainer = styled.section`
  position: relative;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem 2rem;

  ${media.md} {
    min-height: 90vh;
    padding: 5rem 1.5rem 2.5rem;
  }

  ${media.lg} {
    padding: 5.5rem 2rem 3rem;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 3rem;
  animation: ${fadeIn} 0.6s ease-out;
  padding: 0 0.5rem;

  ${media.md} {
    padding: 0 1rem;
    gap: 2.5rem;
  }

  ${media.lg} {
    padding: 0 1.5rem;
  }

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    gap: 1.5rem;
    padding: 0;
  }
`;


const LeftColumn = styled.div`
  flex: 1;
  max-width: 650px;

  @media (max-width: ${breakpoints.md}) {
    text-align: center;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  min-height: 500px;


  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    margin: 0 auto;
    min-height: 450px;
    align-items: center;
  }
`;

const socialLinks = [
  {
    id: 'telegram',
    url: 'https://t.me/fartylandofficial',
    icon: tlogo,
    alt: 'Telegram'
  },
  {
    id: 'twitter',
    url: 'https://x.com/fartyland',
    icon: xlogo,
    alt: 'Twitter'
  },
  {
    id: 'coinmarketcap',
    url: 'https://coinmarketcap.com/dexscan/solana/7fXUVDUqU2CDmijVU94SF2k4XzS6TESaHnFra875qnaA/',
    icon: coinmarketcap,
    alt: 'CoinMarketCap'
  },
  {
    id: 'coingeko',
    url: 'https://www.geckoterminal.com/solana/pools/7fXUVDUqU2CDmijVU94SF2k4XzS6TESaHnFra875qnaA',
    icon: dexview,
    alt: 'Dexview'
  },

  {
    id: 'dextools',
    url: 'https://www.dextools.io/app/solana/pair-explorer/7fXUVDUqU2CDmijVU94SF2k4XzS6TESaHnFra875qnaA',
    icon: dexstools,
    alt: 'Dextools'
  },
  {
    id: 'dexscreener',
    url: 'https://dexscreener.com/solana/7fXUVDUqU2CDmijVU94SF2k4XzS6TESaHnFra875qnaA',
    icon: dexscreener,
    alt: 'Dexscreener'
  },


];




const HeroSection = () => {
  return (
    <HeroContainer>
      <ContentWrapper>
        <LeftColumn>
          <LeftColumn>
            <HeroContent socialLinks={socialLinks} />
          </LeftColumn>
        </LeftColumn>
        <RightColumn>
          <LogoOrbitDisplay />
        </RightColumn>
      </ContentWrapper>
    </HeroContainer>
  );
};

export default HeroSection;