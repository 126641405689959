import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import Sidebar from './Sidebar'
import MobileHeader from './MobileHeader'
import MainContent from './MainContent'

const LayoutWrapper = styled.div`
	min-height: 100vh;
	position: relative;
	overflow: hidden;
`

// Unified navigation data
const navigationItems = [
	{id: 'telegram', label: 'Telegram', url: 'https://t.me/fartylandofficial'},
	{id: 'telegram_mini_app', label: 'Telegram Mini App', url: 'http://t.me/FartyLandbot/FartyLand'},
	{id: 'x', label: 'X', url: 'https://x.com/fartyland'},
	{id: 'introduction', label: 'Introduction'},
	{id: 'mission', label: 'Mission'},
	{id: 'features', label: 'Key Features'},
	{id: 'vision', label: 'Vision'},
	{id: 'taglines', label: 'Taglines'},
	{id: 'tokenomics', label: 'Tokenomics'},
	{id: 'roadmap', label: 'Roadmap'},
	{id: 'conclusion', label: 'Conclusion'}
]

const tableOfContents = [
	{id: 'introduction', label: 'Introduction'},
	{id: 'mission', label: 'Mission'},
	{id: 'features', label: 'Key Features'},
	{id: 'vision', label: 'Vision'},
	{id: 'taglines', label: 'Taglines'},
	{id: 'tokenomics', label: 'Tokenomics'},
	{id: 'roadmap', label: 'Roadmap'},
	{id: 'conclusion', label: 'Conclusion'}
]

const WhitePaperLayout = ({children, scrollPosition, height}) => {
	const [sidebarOpen, setSidebarOpen] = useState(true)
	const [currentSection, setCurrentSection] = useState('introduction')
	const [scrollProgress, setScrollProgress] = useState(0)
	const [isScrolled, setIsScrolled] = useState(false)

	// Refs for sections
	const sectionRefs = useRef({})

	useEffect(() => {
		const scrolled = (scrollPosition / height) * 100
		setScrollProgress(scrolled)

		setIsScrolled(scrollPosition > 10)

		const sections = document.querySelectorAll('section[id]')
		const headerOffset = window.innerWidth < 992 ? 80 : 32

		let currentSectionFound = false
		for (let i = sections.length - 1; i >= 0; i--) {
			const section = sections[i]
			const rect = section.getBoundingClientRect()

			if (rect.top <= headerOffset + 55) {
				if (section.id !== currentSection) {
					setCurrentSection(section.id)
				}
				currentSectionFound = true
				break
			}
		}

		if (!currentSectionFound) {
			setCurrentSection('introduction')
		}
	}, [scrollPosition, currentSection])

	const handleNavigation = (sectionId) => {
		const element = sectionRefs.current[sectionId]

		if (element) {
			const isMobile = window.innerWidth < 992

			if (isMobile) {
				setSidebarOpen(false)
			}

			const rect = element.getBoundingClientRect()
			const scrollOffset = window.pageYOffset || document.documentElement.scrollTop
			const elementTop = rect.top + scrollOffset
			const headerOffset = isMobile ? 64 : 0
			const extraPadding = 32
			const scrollToPosition = elementTop - headerOffset - extraPadding

			setTimeout(
				() => {
					window.scrollTo({
						top: scrollToPosition,
						behavior: 'smooth'
					})

					setCurrentSection(sectionId)
				},
				isMobile ? 300 : 0
			)
		}
	}

	useEffect(() => {
		if (window.innerWidth > 600) {
			setSidebarOpen(true)
		}
	}, [window.innerWidth])

	return (
		<LayoutWrapper>
			<MobileHeader isOpen={sidebarOpen} onToggle={() => setSidebarOpen(!sidebarOpen)} scrollProgress={scrollProgress} isScrolled={isScrolled} />

			<Sidebar isOpen={sidebarOpen} activeSection={currentSection} onNavigate={handleNavigation} navigationItems={navigationItems} onToggle={() => setSidebarOpen(!sidebarOpen)} />

			<MainContent sidebarOpen={sidebarOpen} scrollProgress={scrollProgress} currentSection={currentSection} tableOfContents={tableOfContents} scrollPosition={scrollPosition}>
				{children}
			</MainContent>
		</LayoutWrapper>
	)
}

export default WhitePaperLayout
