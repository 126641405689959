import React from 'react'
import styled from 'styled-components'

const Section = styled.section`
	padding: 2rem;
	background: rgba(76, 140, 47, 0.1);
	backdrop-filter: blur(10px);
	border-radius: 1rem;
	border: 1px solid rgba(76, 140, 47, 0.2);
	margin-bottom: 2rem;
`

const Title = styled.h2`
	font-family: 'Exo 2', sans-serif;
	font-size: clamp(2rem, 3vw, 2.5rem);
	font-weight: 800;
	color: #ffffff;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	line-height: 1.2;
	text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(76, 140, 47, 0.4), 4px 4px 0 #8b4513;
	-webkit-text-stroke: 1px #4c8c2f;
	margin-bottom: 1.5rem;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: -0.5rem;
		left: 0;
		width: 100px;
		height: 4px;
		background: linear-gradient(to right, #8b4513, transparent);
	}
	@media (max-width: 768px) {
		font-size: clamp(1.2rem, 3vw, 2.5rem);
	}
`

const Content = styled.div`
	font-family: 'Roboto', sans-serif;
	font-size: clamp(1.1rem, 1.2vw, 1.25rem);
	line-height: 1.8;
	color: #ffffff;
	max-width: 800px;
	margin: 0 auto;
	background: linear-gradient(to right, #ffffff, #e0e0e0);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	p {
		margin-bottom: 1.5rem;
	}
`

const Highlight = styled.span`
	color: #ffd700;
	font-weight: 700;
	text-shadow: 0 0 5px rgba(255, 204, 0, 0.4);
	-webkit-text-fill-color: #ffd700;
	position: relative;
	display: inline-block;
	padding: 0 0.1em;

	&::after {
		content: '';
		position: absolute;
		bottom: -2px;
		left: 0;
		width: 100%;
		height: 2px;
		background: rgba(255, 215, 0, 0.3);
	}
`

const Tagline = styled.div`
	font-family: 'Exo 2', sans-serif;
	font-size: clamp(1.3rem, 2vw, 1.6rem);
	font-weight: 800;
	color: #ffd700;
	text-shadow: 0 0 10px rgba(255, 204, 0, 0.4), 2px 2px 0 rgba(139, 69, 19, 0.8);
	letter-spacing: 0.05em;
	text-align: center;
	margin-top: 2rem;
	-webkit-text-fill-color: #ffd700;
`

const Mission = () => {
	return (
		<Section id="mission">
			<Title>Mission</Title>
			<Content>
				<p>
					Farty's mission is simple yet impactful: to normalize farting as a healthy bodily function through humor, education, and positivity. We strive to build a community where people can laugh, learn, and share without fear of
					embarrassment. Through the Farty ecosystem, we promote digestive health awareness and encourage everyone to <Highlight>"Fart Proudly."</Highlight>
				</p>
			</Content>
			<Tagline>Let's break the taboo together! </Tagline>
		</Section>
	)
}

export default Mission
