import React from 'react'
import styled, {keyframes} from 'styled-components'

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`

const Section = styled.section`
	padding: 2rem;
	animation: ${fadeIn} 0.3s ease-out;
	background: rgba(76, 140, 47, 0.1);
	backdrop-filter: blur(10px);
	border-radius: 1rem;
	border: 1px solid rgba(76, 140, 47, 0.2);
	margin-bottom: 2rem;
`

const Title = styled.h1`
	font-family: 'Exo 2', sans-serif;
	font-size: clamp(2.5rem, 4vw, 4.2rem);
	font-weight: 800;
	color: #ffffff;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	line-height: 1.1;
	text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(76, 140, 47, 0.4), 4px 4px 0 #8b4513;
	-webkit-text-stroke: 2px #4c8c2f;
	text-align: center;
	margin-bottom: 2rem;

	@media (max-width: 768px) {
		font-size: clamp(1.2rem, 3vw, 2.5rem);
		-webkit-text-stroke: 1px #4c8c2f;
	}
`

const Content = styled.div`
	font-family: 'Roboto', sans-serif;
	font-size: clamp(1.1rem, 1.2vw, 1.25rem);
	line-height: 1.8;
	color: #ffffff;
	max-width: 800px;
	margin: 0 auto;
	background: linear-gradient(to right, #ffffff, #e0e0e0);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	p {
		margin-bottom: 1.5rem;
	}
`

const TitleUnderline = styled.div`
	width: 100%;
	height: 4px;
	background: linear-gradient(to right, transparent, #8b4513, transparent);
	opacity: 0.8;
	margin: 0.75rem auto 2rem;
	max-width: 200px;
`

const Introduction = () => {
	return (
		<Section id="introduction">
			<Title>Welcome To Farty</Title>
			<TitleUnderline />
			<Content>
				<p>
					Farty is more than a project; it's a movement to normalize one of the most natural human processes—farting. By embracing humor, education, and inclusivity, Farty aims to eliminate the stigma surrounding digestive health and create
					a global community that celebrates acceptance and wellness. At its core, Farty leverages blockchain technology and gamification to empower individuals while promoting a healthier and happier lifestyle.
				</p>
			</Content>
		</Section>
	)
}

export default Introduction
