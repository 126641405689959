import React from 'react'
import styled from 'styled-components'
import {MessageCircle} from 'lucide-react'

const Section = styled.section`
	padding: 2rem;
	background: rgba(76, 140, 47, 0.1);
	backdrop-filter: blur(10px);
	border-radius: 1rem;
	border: 1px solid rgba(76, 140, 47, 0.2);
	margin-bottom: 2rem;
`

const Title = styled.h2`
	font-family: 'Exo 2', sans-serif;
	font-size: clamp(2rem, 3vw, 2.5rem);
	font-weight: 800;
	color: #ffffff;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	line-height: 1.2;
	text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(76, 140, 47, 0.4), 4px 4px 0 #8b4513;
	-webkit-text-stroke: 1px #4c8c2f;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	gap: 1rem;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: -0.5rem;
		left: 0;
		width: 100px;
		height: 4px;
		background: linear-gradient(to right, #8b4513, transparent);
	}
	@media (max-width: 768px) {
		font-size: clamp(1.2rem, 3vw, 2.5rem);
	}
`

const IconWrapper = styled.div`
	width: 50px;
	height: 50px;
	background: rgba(76, 140, 47, 0.3);
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		color: #ffffff;
		width: 30px;
		height: 30px;
	}

	@media (max-width: 768px) {
		width: 40px;
		height: 40px;
		svg {
			color: #ffffff;
			width: 20px;
			height: 20px;
		}
	}
`

const TaglinesGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1.5rem;
	margin-top: 2rem;

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`

const TaglineCard = styled.div`
	background: rgba(76, 140, 47, 0.15);
	border-radius: 1rem;
	padding: 1.5rem;
	border: 1px solid rgba(76, 140, 47, 0.3);
	text-align: center;
	transition: transform 0.2s ease, background-color 0.2s ease;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100px;

	&:hover {
		background: rgba(76, 140, 47, 0.25);
		transform: translateY(-2px);
	}
`

const TaglineText = styled.h3`
	font-family: 'Exo 2', sans-serif;
	font-size: clamp(1.25rem, 1.5vw, 1.75rem);
	font-weight: 800;
	color: #ffd700;
	margin: 0;
	padding: 0.5rem;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
	word-break: break-word;
	hyphens: auto;
`

const taglines = [{text: 'Fart Proudly'}, {text: 'Fart Loudly'}, {text: "Let's Farty"}, {text: '#FreedomForFarting'}]

const Taglines = () => {
	return (
		<Section id="taglines">
			<Title>
				<IconWrapper>
					<MessageCircle />
				</IconWrapper>
				Taglines
			</Title>

			<TaglinesGrid>
				{taglines.map((tagline, index) => (
					<TaglineCard key={index}>
						<TaglineText>{tagline.text}</TaglineText>
					</TaglineCard>
				))}
			</TaglinesGrid>
		</Section>
	)
}

export default Taglines
