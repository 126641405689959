import React from 'react'
import styled from 'styled-components'
import {Gamepad, Coins, Users} from 'lucide-react'

const Section = styled.section`
	padding: 2rem;
	background: rgba(76, 140, 47, 0.1);
	backdrop-filter: blur(10px);
	border-radius: 1rem;
	border: 1px solid rgba(76, 140, 47, 0.2);
	margin-bottom: 2rem;
`

const Title = styled.h2`
	font-family: 'Exo 2', sans-serif;
	font-size: clamp(2rem, 3vw, 2.5rem);
	font-weight: 800;
	color: #ffffff;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	line-height: 1.2;
	text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(76, 140, 47, 0.4), 4px 4px 0 #8b4513;
	-webkit-text-stroke: 1px #4c8c2f;
	margin-bottom: 2rem;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: -0.5rem;
		left: 0;
		width: 100px;
		height: 4px;
		background: linear-gradient(to right, #8b4513, transparent);
	}
	@media (max-width: 768px) {
		font-size: clamp(1.2rem, 3vw, 2.5rem);
	}
`

const SubTitle = styled.h3`
	font-family: 'Exo 2', sans-serif;
	font-size: clamp(1.5rem, 2vw, 1.8rem);
	color: #ffffff;
	margin: 2rem 0 1rem;
	display: flex;
	align-items: center;
	gap: 1rem;
	text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
`

const FeatureGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	margin-top: 2rem;
`

const FeatureCard = styled.div`
	background: rgba(76, 140, 47, 0.15);
	border-radius: 1rem;
	padding: 2rem;
	border: 1px solid rgba(76, 140, 47, 0.3);
	transition: transform 0.2s ease, box-shadow 0.2s ease;
	backdrop-filter: blur(10px); // Added for better contrast

	&:hover {
		transform: translateY(-5px);
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	}
`

const IconWrapper = styled.div`
	width: 50px;
	height: 50px;
	background: rgba(76, 140, 47, 0.3);
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 1rem;

	svg {
		color: #ffffff;
		width: 30px;
		height: 30px;
	}
	@media (max-width: 768px) {
		width: 40px;
		height: 40px;
		svg {
			color: #ffffff;
			width: 20px;
			height: 20px;
		}
	}
`

const Content = styled.div`
	font-family: 'Roboto', sans-serif;
	font-size: clamp(1.1rem, 1.2vw, 1.25rem);
	line-height: 1.8;
	color: rgba(255, 255, 255, 0.9); // Direct white color with slight transparency

	ul {
		list-style: none;
		padding: 0;
		margin: 1rem 0;
	}

	li {
		margin-bottom: 1rem;
		padding-left: 1.5rem;
		position: relative;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); // Subtle text shadow for better readability

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
`

const Features = () => {
	const features = [
		{
			id: 'ecosystem',
			title: 'Fartyland Ecosystem',
			icon: <Gamepad />,
			content: [
				'Fartyland Game: An engaging and entertaining platform where players complete challenges, earn rewards, and compete in humorous scenarios.',
				'FartTok Portal: A social platform dedicated to sharing funny, educational, and uplifting content about digestive health and farting.',
				'AI Wellness Agent: A cutting-edge AI tool that offers personalized health tips, lifestyle recommendations, and education about digestive health.'
			]
		},
		{
			id: 'token',
			title: 'Farty Token',
			icon: <Coins />,
			content: [
				'A utility token that powers the Farty ecosystem.',
				'Users earn rewards in Farty tokens by participating in games, sharing content on FartTok, or engaging with the AI Wellness Agent.',
				'The token facilitates in-game purchases, unlocks premium content, and supports charitable initiatives focused on digestive health awareness.'
			]
		},
		{
			id: 'community',
			title: 'Community-Centric Approach',
			icon: <Users />,
			content: [
				'A vibrant, cult-like community where humor and inclusivity reign supreme.',
				'Events, challenges, and campaigns that bring people together, such as "Let\'s Farty" gatherings.',
				'A commitment to spreading the message that "It\'s okay to fart" through memes, merchandise, and educational outreach.'
			]
		}
	]

	return (
		<Section id="features">
			<Title>Key Features</Title>
			<FeatureGrid>
				{features.map((feature) => (
					<FeatureCard key={feature.id} id={feature.id}>
						<IconWrapper>{feature.icon}</IconWrapper>
						<SubTitle>{feature.title}</SubTitle>
						<Content>
							<ul>
								{feature.content.map((item, index) => (
									<li key={index}>{item}</li>
								))}
							</ul>
						</Content>
					</FeatureCard>
				))}
			</FeatureGrid>
		</Section>
	)
}

export default Features
