import React from 'react'
import styled from 'styled-components'
import {Coins} from 'lucide-react'

const Section = styled.section`
	padding: 2rem;
	background: rgba(76, 140, 47, 0.1);
	backdrop-filter: blur(10px);
	border-radius: 1rem;
	border: 1px solid rgba(76, 140, 47, 0.2);
	margin-bottom: 2rem;
`

const Title = styled.h2`
	font-family: 'Exo 2', sans-serif;
	font-size: clamp(2rem, 3vw, 2.5rem);
	font-weight: 800;
	color: rgba(255, 255, 255, 0.9);
	text-transform: uppercase;
	letter-spacing: 0.1em;
	line-height: 1.2;
	text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(76, 140, 47, 0.4), 4px 4px 0 #8b4513;
	-webkit-text-stroke: 1px #4c8c2f;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	gap: 1rem;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: -0.5rem;
		left: 0;
		width: 100px;
		height: 4px;
		background: linear-gradient(to right, #8b4513, transparent);
	}
	@media (max-width: 768px) {
		font-size: clamp(1.2rem, 3vw, 2.5rem);
	}
`

const IconWrapper = styled.div`
	width: 50px;
	height: 50px;
	background: rgba(76, 140, 47, 0.3);
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		color: #ffffff;
		width: 30px;
		height: 30px;
	}

	@media (max-width: 768px) {
		width: 40px;
		height: 40px;
		svg {
			color: #ffffff;
			width: 20px;
			height: 20px;
		}
	}
`

const TokenomicsGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 2rem;
	margin-top: 2rem;
`

const TokenomicsCard = styled.div`
	background: rgba(76, 140, 47, 0.15);
	border-radius: 1rem;
	padding: 2rem;
	border: 1px solid rgba(76, 140, 47, 0.3);
	text-align: center;
	transition: transform 0.3s ease, box-shadow 0.3s ease;

	&:hover {
		transform: translateY(-5px);
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	}
`

const TokenomicsValue = styled.div`
	font-family: 'Exo 2', sans-serif;
	font-size: clamp(2rem, 3vw, 2.5rem);
	font-weight: 800;
	color: #ffd700;
	text-shadow: 0 0 10px rgba(255, 204, 0, 0.4);
	margin: 1rem 0;
`

const TokenomicsLabel = styled.div`
	font-family: 'Exo 2', sans-serif;
	font-size: clamp(1.1rem, 1.2vw, 1.25rem);
	color: #ffffff;
	margin-bottom: 1rem;
`

const TokenomicsDescription = styled.div`
	font-family: 'Roboto', sans-serif;
	font-size: 1rem;
	line-height: 1.6;
	color: #ffffff;
	background: linear-gradient(to right, #ffffff, #e0e0e0);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
`

const TotalSupply = styled.div`
	background: rgba(76, 140, 47, 0.15);
	border-radius: 1rem;
	padding: 2rem;
	border: 1px solid rgba(76, 140, 47, 0.3);
	text-align: center;
	margin-top: 2rem;

	h3 {
		font-family: 'Exo 2', sans-serif;
		font-size: clamp(1.5rem, 2vw, 2rem);
		color: #ffffff;
		margin-bottom: 1rem;
	}

	div {
		font-family: 'Exo 2', sans-serif;
		font-size: clamp(1.8rem, 2.5vw, 3rem);
		font-weight: 800;
		color: #ffd700;
		text-shadow: 0 0 10px rgba(255, 204, 0, 0.4);
	}
`

const Tokenomics = () => {
	const tokenomicsData = [
		{
			value: '100%',
			label: 'Liquidity Pool',
			description: 'Maximum liquidity for stability'
		},
		{
			value: '0%',
			label: 'Tax',
			description: 'Simple, tax-free trading'
		}
	]

	return (
		<Section id="tokenomics">
			<Title>
				<IconWrapper>
					<Coins />
				</IconWrapper>
				Tokenomics
			</Title>

			<TokenomicsGrid>
				{tokenomicsData.map((item, index) => (
					<TokenomicsCard key={index}>
						<TokenomicsValue>{item.value}</TokenomicsValue>
						<TokenomicsLabel>{item.label}</TokenomicsLabel>
						<TokenomicsDescription>{item.description}</TokenomicsDescription>
					</TokenomicsCard>
				))}
			</TokenomicsGrid>

			<TotalSupply>
				<h3>Total Supply</h3>
				<div>1,000,000,000 FARTY</div>
			</TotalSupply>
		</Section>
	)
}

export default Tokenomics
