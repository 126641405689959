import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Droplet, Percent, Coins } from 'lucide-react';
import tokenLogo1 from '../assets/memecoinengineergreen.png';
import tokenLogo2 from '../assets/memecointeachergreen.png';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const TokenomicsContainer = styled.section`
  position: relative;
  padding: 3rem 1rem;
  overflow: hidden;
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-out forwards;

  @media (min-width: 768px) {
    padding: 4rem 1.5rem;
  }

  @media (min-width: 992px) {
    padding: 5rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2;
  text-align: center;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
               0 0 20px rgba(76, 140, 47, 0.4),
               4px 4px 0 #8b4513;
  -webkit-text-stroke: 2px #4c8c2f;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 4px;
    background: linear-gradient(to right, transparent, #8b4513, transparent);
    opacity: 0.8;
  }
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin: 2rem auto;
  max-width: 800px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const StatCard = styled.div`
  background: rgba(76, 140, 47, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(76, 140, 47, 0.3);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  animation-delay: ${props => props.$delay}s;

  &:hover {
    transform: translateY(-5px);
  }
`;

const BackgroundLogo = styled.div`
  position: absolute;
  width: 500px;
  height: 500px;
  background: url(${props => props.$logoSrc}) no-repeat center center;
  background-size: contain;
  opacity: 0.15;
  pointer-events: none;
  
  ${props => props.$position === 'left' ? `
    left: -30px;
    top: 50%;
    transform: translateY(-50%) rotate(-15deg);
  ` : `
    right: -30px;
    bottom: 50%;
    transform: translateY(50%) rotate(15deg);
  `}

  @media (max-width: 1200px) {
    width: 300px;
    height: 300px;
    ${props => props.$position === 'left' ? `
      left: -50px;
    ` : `
      right: -50px;
    `}
  }

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
    opacity: 0.15;
    
    ${props => props.$position === 'left' ? `
      top: 12%;
      left: -30px;
      transform: rotate(-15deg);
    ` : `
      bottom: 28%;
      right: -22px;
      transform: rotate(15deg);
    `}
  }
`;
const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: #ffffff;
`;

const StatValue = styled.div`
  font-family: 'Exo 2', sans-serif;
  font-size: 2.5rem;
  font-weight: 800;
  color: #ffcc00;
  text-shadow: 0 0 5px rgba(255, 204, 0, 0.4);
  margin: 0.5rem 0;
`;

const StatLabel = styled.div`
  font-family: 'Exo 2', sans-serif;
  font-size: 1.25rem;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const TotalSupplyWrapper = styled.div`
  background: rgba(76, 140, 47, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 2.5rem;
  text-align: center;
  margin: 3rem auto;
  max-width: 600px;
  border: 1px solid rgba(76, 140, 47, 0.3);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);

  h3 {
    font-family: 'Exo 2', sans-serif;
    font-size: clamp(1.5rem, 3vw, 2rem);
    font-weight: 800;
    color: #ffffff;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
  }

  .amount {
    font-family: 'Exo 2', sans-serif;
    font-size: clamp(1.75rem, 4vw, 2.5rem);
    font-weight: 800;
    color: #ffcc00;
    text-shadow: 0 0 10px rgba(255, 204, 0, 0.4);

    span {
      color: #ffffff;
      font-size: 0.8em;
    }
  }
`;

const Description = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: clamp(1rem, 1.2vw, 1.25rem);
  line-height: 1.6;
  color: #ffffff;
  max-width: 800px;
  margin: 0 auto 1.5rem;
  background: linear-gradient(to right, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  span {
    color: #ffcc00;
    text-shadow: 0 0 5px rgba(255, 204, 0, 0.8);
  }
`;

const TokenomicsSection = () => {
  const stats = [
    {
      icon: Droplet,
      value: "100%",
      label: "Liquidity Pool",
      description: "Maximum liquidity for stability"
    },
    {
      icon: Percent,
      value: "0%",
      label: "Tax",
      description: "Simple, tax-free trading"
    }
  ];

  return (
    <TokenomicsContainer id="tokenomics">
      <BackgroundLogo $position="left" $logoSrc={tokenLogo1} />
      <BackgroundLogo $position="right" $logoSrc={tokenLogo2} />
      <ContentWrapper>
        <Title>FARTONOMICS</Title>
        <Description>
          The most <span>transparent</span> and <span>fair</span> tokenomics in the space! 
          <br />
          With <span>100%</span> of tokens in liquidity, we're keeping it simple and pure for all <span>$FARTY</span> riders!
        </Description>

        <StatsGrid>
          {stats.map((stat, index) => (
            <StatCard key={index} $delay={index * 0.2}>
              <IconWrapper>
                <stat.icon size={45} weight="fill" />
              </IconWrapper>
              <StatValue>{stat.value}</StatValue>
              <StatLabel>{stat.label}</StatLabel>
              <StatLabel style={{ 
                fontSize: '0.9rem', 
                opacity: 0.8,
                marginTop: '0.5rem'
              }}>
                {stat.description}
              </StatLabel>
            </StatCard>
          ))}
        </StatsGrid>

        <TotalSupplyWrapper>
          <IconWrapper style={{ margin: '0 auto 1.5rem' }}>
            <Coins size={45} weight="fill" />
          </IconWrapper>
          <h3>Total $FARTY Supply</h3>
          <div className="amount">
            1,000,000,000 <span>$FARTY</span>
          </div>
        </TotalSupplyWrapper>
      </ContentWrapper>
    </TokenomicsContainer>
  );
};

export default TokenomicsSection;