import React from 'react'
import styled from 'styled-components'
import {Milestone} from 'lucide-react'

const breakpoints = {
	xs: '360px',
	sm: '576px',
	md: '768px',
	lg: '992px',
	xl: '1200px'
}

const media = {
	xs: `@media (min-width: ${breakpoints.xs})`,
	sm: `@media (min-width: ${breakpoints.sm})`,
	md: `@media (min-width: ${breakpoints.md})`,
	lg: `@media (min-width: ${breakpoints.lg})`,
	xl: `@media (min-width: ${breakpoints.xl})`
}

const Section = styled.section`
	padding: 2rem;
	background: rgba(76, 140, 47, 0.1);
	backdrop-filter: blur(10px);
	border-radius: 1rem;
	border: 1px solid rgba(76, 140, 47, 0.2);
	margin-bottom: 2rem;

	${media.xs} {
		padding: 0.2rem;
	}
	${media.sm} {
		padding: 0.2rem;
	}

	${media.md} {
		padding: 2rem;
	}

	${media.lg} {
		padding: 2rem;
	}
`

const Title = styled.h2`
	font-family: 'Exo 2', sans-serif;
	font-size: clamp(2rem, 3vw, 2.5rem);
	font-weight: 800;
	color: #ffffff;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	line-height: 1.2;
	text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(76, 140, 47, 0.4), 4px 4px 0 #8b4513;
	-webkit-text-stroke: 1px #4c8c2f;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	gap: 1rem;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: -0.5rem;
		left: 0;
		width: 100px;
		height: 4px;
		background: linear-gradient(to right, #8b4513, transparent);
	}
	@media (max-width: 768px) {
		font-size: clamp(1.2rem, 3vw, 2.5rem);
	}
`

const IconWrapper = styled.div`
	width: 50px;
	height: 50px;
	background: rgba(76, 140, 47, 0.3);
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		color: #ffffff;
		width: 30px;
		height: 30px;
	}
	@media (max-width: 768px) {
		width: 40px;
		height: 40px;
		svg {
			color: #ffffff;
			width: 20px;
			height: 20px;
		}
	}
`

const Timeline = styled.div`
	position: relative;
	max-width: 800px;
	margin: 2rem auto;
	padding: 0 1rem;

	&::before {
		content: '';
		position: absolute;
		width: 4px;
		background: rgba(76, 140, 47, 0.3);
		top: 0;
		bottom: 0;
		left: calc(50% - 2px);
		border-radius: 2px;

		@media (max-width: 768px) {
			left: 30px;
		}
	}
`

const TimelineItem = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-right: 50%;
	position: relative;
	margin-bottom: 3rem;

	&:nth-child(odd) {
		justify-content: flex-start;
		padding-right: 0;
		padding-left: 50%;
	}

	@media (max-width: 768px) {
		justify-content: flex-start;
		padding-right: 0;
		padding-left: 60px;

		&:nth-child(odd) {
			padding-left: 60px;
		}
	}
`

const TimelineContent = styled.div`
	background: rgba(76, 140, 47, 0.15);
	backdrop-filter: blur(10px);
	border-radius: 1rem;
	padding: 1.5rem;
	border: 1px solid rgba(76, 140, 47, 0.3);
	width: calc(100% - 2rem);
	position: relative;
	transition: transform 0.3s ease, box-shadow 0.3s ease;

	&:hover {
		transform: translateY(-5px);
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	}

	&::before {
		content: '';
		position: absolute;
		width: 20px;
		height: 20px;
		background: #4c8c2f;
		border-radius: 50%;
		top: 50%;
		transform: translateY(-50%);
		right: -60px;
		box-shadow: 0 0 10px rgba(76, 140, 47, 0.5);

		${TimelineItem}:nth-child(odd) & {
			left: -60px;
			right: auto;
		}

		@media (max-width: 768px) {
			left: -45px !important;
			right: auto;
		}
	}
`

const PhaseTitle = styled.h3`
	font-family: 'Exo 2', sans-serif;
	font-size: clamp(1.3rem, 2vw, 1.5rem);
	color: #ffd700;
	text-shadow: 0 0 10px rgba(255, 204, 0, 0.4);
	margin-bottom: 1rem;
`

const PhaseList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	font-family: 'Roboto', sans-serif;
	font-size: 1rem;
	line-height: 1.6;
	color: rgba(255, 255, 255, 0.9); // Direct white color with slight transparency
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); // Subtle text shadow for readability

	li {
		margin-bottom: 0.5rem;
		padding-left: 1.5rem;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
`

const Roadmap = () => {
	const phases = [
		{
			title: 'Phase 1: Launch',
			items: ['Community Building', 'FARTY Contract Creation', 'FARTY Token Marketing', 'FARTY Contests for Community', 'Beta version of the farting GAME', 'Fart upload and Chat Platform', 'Partnerships with Fart-Friendly Projects']
		},
		{
			title: 'Phase 2: Growth',
			items: ['Launch on Dex', 'Coingecko Listings', 'CEX Listings', 'Major FARTY Marketing Campaign', '1,500+ FARTY Holders', 'Develop FARTY Fart Game Two', 'Expand FARTY Ecosystem']
		},
		{
			title: 'Phase 3: Moon',
			items: ['Tier 1 CEX Listings', '$100M FARTY Market Cap', 'Fancy Merch for $FARTY Fans', '10,000+ FARTY Holders', 'FARTY Explorer and Wallet', 'Release FARTY Fart Game Three', 'Release FartyLand Roadmap for Cartoon Network']
		},
		{
			title: 'Phase 4: Legacy',
			items: ['Establishing Farty as the leading voice in digestive health awareness', 'Creating long-term partnerships to sustain the movement', 'Continuous development of the Farty ecosystem']
		}
	]

	return (
		<Section id="roadmap">
			<Title>
				<IconWrapper>
					<Milestone />
				</IconWrapper>
				Fartastic Roadmap
			</Title>

			<Timeline>
				{phases.map((phase, index) => (
					<TimelineItem key={index}>
						<TimelineContent>
							<PhaseTitle>{phase.title}</PhaseTitle>
							<PhaseList>
								{phase.items.map((item, itemIndex) => (
									<li key={itemIndex}>{item}</li>
								))}
							</PhaseList>
						</TimelineContent>
					</TimelineItem>
				))}
			</Timeline>
		</Section>
	)
}

export default Roadmap
