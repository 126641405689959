import React, {useEffect} from 'react'
import styled, {keyframes} from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const MainWrapper = styled.main`
	flex: 1;
	min-height: 100vh;
	margin-left: ${(props) => (props.$sidebarOpen ? '300px' : '0')};
	padding: calc(64px + 2rem) 2rem 2rem;
	transition: margin-left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	background: #1a1a1a;
	position: relative;

	@media (max-width: 991px) {
		margin-left: 0;
		padding: calc(64px + 1rem) 1rem 1rem;
	}

	@media (min-width: 992px) {
		padding-top: 2rem;
	}

	@media (min-width: 1200px) {
		padding-left: 3rem;
		padding-right: 3rem;
	}
`

const ContentContainer = styled.div`
	max-width: 1200px;
	margin: 0 auto;
	overflow-y: scroll;
	animation: ${fadeIn} 0.3s ease-out;
`

const Section = styled.section`
	margin-bottom: 3rem;
	opacity: 0;
	animation: ${fadeIn} 0.3s ease-out forwards;
	animation-delay: ${(props) => props.$delay || '0s'};
	scroll-margin-top: calc(64px + 2rem);

	@media (min-width: 992px) {
		scroll-margin-top: 2rem;
	}

	&:last-child {
		margin-bottom: 0;
	}
`

const BackToTop = styled.button`
	position: fixed;
	bottom: 2rem;
	right: 2rem;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: rgba(76, 140, 47, 0.9);
	border: none;
	color: white;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s ease;
	opacity: ${(props) => (props.$visible ? '1' : '0')};
	transform: translateY(${(props) => (props.$visible ? '0' : '20px')});
	pointer-events: ${(props) => (props.$visible ? 'auto' : 'none')};
	backdrop-filter: blur(5px);
	z-index: 30;

	&:hover {
		background: rgba(76, 140, 47, 1);
		transform: translateY(-2px);
	}

	&:active {
		transform: translateY(0);
	}

	@media print {
		display: none;
	}
`

const TableOfContents = styled.nav`
	position: fixed;
	top: 50%;
	right: 2rem;
	z-index: 1;
	transform: translateY(-50%);
	display: none;

	@media (min-width: 1400px) {
		display: block;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		margin: 0.5rem 0;
	}

	a {
		color: rgba(255, 255, 255, 0.6);
		text-decoration: none;
		font-size: 0.875rem;
		transition: all 0.2s ease;
		display: flex;
		align-items: center;
		padding: 0.25rem 0;

		&::before {
			content: '';
			width: 2px;
			height: 20px;
			background: rgba(76, 140, 47, 0.3);
			margin-right: 0.5rem;
			transition: all 0.2s ease;
		}

		&:hover,
		&.active {
			color: #ffffff;

			&::before {
				background: #4c8c2f;
				width: 3px;
			}
		}
	}
`

const PageProgress = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	width: 3px;
	height: 100%;
	background: rgba(76, 140, 47, 0.1);
	z-index: 20;

	@media (max-width: 991px) {
		display: none;
	}
`

const ProgressIndicator = styled.div`
	width: 100%;
	background: #4c8c2f;
	height: ${(props) => props.$progress}%;
	transition: height 0.2s ease;
`

const MainContent = ({children, sidebarOpen, scrollProgress = 0, currentSection = '', tableOfContents = [], scrollPosition}) => {
	const [showBackToTop, setShowBackToTop] = React.useState(false)
	const contentRef = React.useRef(null)

	React.useEffect(() => {
		if (scrollPosition > 400) {
			setShowBackToTop(true)
		} else {
			setShowBackToTop(false)
		}
	}, [scrollPosition])

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
		if (contentRef.current) {
			contentRef.current.scrollIntoView({behavior: 'smooth'})
		}
	}

	return (
		<MainWrapper $sidebarOpen={sidebarOpen}>
			<ContentContainer ref={contentRef}>
				{React.Children.map(children, (child) => {
					// Each section already has its own ID from the component
					return child
				})}
			</ContentContainer>

			<TableOfContents>
				<ul>
					{tableOfContents.map((item) => (
						<li key={item.id}>
							<a
								href={`#${item.id}`}
								className={currentSection === item.id ? 'active' : ''}
								onClick={(e) => {
									e.preventDefault()
									const element = document.getElementById(item.id)
									if (element) {
										element.scrollIntoView({behavior: 'smooth'})
									}
								}}>
								{item.label}
							</a>
						</li>
					))}
				</ul>
			</TableOfContents>

			<PageProgress>
				<ProgressIndicator $progress={scrollProgress} />
			</PageProgress>

			<BackToTop onClick={scrollToTop} $visible={showBackToTop} aria-label="Scroll to top">
				↑
			</BackToTop>
		</MainWrapper>
	)
}
export default MainContent
