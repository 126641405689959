import React from 'react'
import styled from 'styled-components'
import {Menu, X} from 'lucide-react'

const HeaderWrapper = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 64px;
	background: rgba(76, 140, 47, 0.15);
	backdrop-filter: blur(10px);
	border-bottom: 1px solid rgba(76, 140, 47, 0.3);
	z-index: 40;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1.5rem;
	transition: background-color 0.3s ease;

	@media (min-width: 992px) {
		display: none;
	}

	/* Add subtle shadow when scrolled */
	${(props) =>
		props.$isScrolled &&
		`
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    background: rgba(76, 140, 47, 0.25);
  `}
`

const MenuButton = styled.button`
	background: none;
	border: none;
	width: 40px;
	height: 40px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: #ffffff;
	transition: all 0.2s ease;
	position: relative;
	overflow: hidden;

	&:hover {
		background: rgba(255, 255, 255, 0.1);
	}

	&:active {
		transform: scale(0.95);
	}

	/* Ripple effect on click */
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.2);
		border-radius: 50%;
		transform: scale(0);
		transition: transform 0.3s ease-out;
	}

	&:active::after {
		transform: scale(2);
		opacity: 0;
	}
`

const Title = styled.h1`
	font-family: 'Exo 2', sans-serif;
	font-size: 1.25rem;
	font-weight: 800;
	color: #ffffff;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	margin: 0;
	background: linear-gradient(to right, #ffffff, #e0e0e0);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

const ProgressBar = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background: rgba(76, 140, 47, 0.3);
	overflow: hidden;
`

const ProgressIndicator = styled.div`
	height: 100%;
	background: #4c8c2f;
	width: ${(props) => props.$progress}%;
	transition: width 0.2s ease;
`

const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	transition: transform 0.3s ease;
	transform: ${(props) => (props.$isOpen ? 'rotate(180deg)' : 'rotate(0)')};
`

const MobileHeader = ({isOpen, onToggle, title = 'Farty Lightpaper', scrollProgress = 0, isScrolled = false}) => {
	return (
		<HeaderWrapper $isScrolled={isScrolled}>
			<MenuButton onClick={onToggle} aria-label={isOpen ? 'Close menu' : 'Open menu'} aria-expanded={isOpen}>
				<IconWrapper $isOpen={isOpen}>{isOpen ? <X size={24} /> : <Menu size={24} />}</IconWrapper>
			</MenuButton>

			<Title>{title}</Title>

			<MenuButton style={{visibility: 'hidden'}}>
				{/* Placeholder for flex spacing */}
				<Menu size={24} />
			</MenuButton>

			<ProgressBar>
				<ProgressIndicator $progress={scrollProgress} />
			</ProgressBar>
		</HeaderWrapper>
	)
}

export default MobileHeader
