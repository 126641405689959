import React from 'react';
import styled, { keyframes } from 'styled-components';
import aboutLogo from '../assets/aboutlogo.webp';
import aboutTextBgLogo from '../assets/herologo.webp'; 

const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

// Keep one simple float animation for the logo
const gentleFloat = keyframes`
  0% { transform: translateY(0) }
  50% { transform: translateY(-10px) }
  100% { transform: translateY(0) }
`;

const AboutContainer = styled.section`
  position: relative;
  padding: 3rem 1rem;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;

  @keyframes fadeIn {
    to { opacity: 1; }
  }

  ${media.md} {
    padding: 4rem 1.5rem;
  }

  ${media.lg} {
    padding: 5rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const ImageColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.md}) {
    order: -1;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const LogoContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  height: auto;
  margin: 0 auto;
  animation: ${gentleFloat} 3s ease-in-out infinite;
  transform: translateZ(0); // Hardware acceleration

  @media (max-width: ${breakpoints.md}) {
    max-width: 300px;
  }

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  transition: transform 0.3s ease;
  transform: translateZ(0);

  &:hover {
    transform: scale(1.03);
  }
`;

const LogoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 0;
  transition: opacity 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const LogoText = styled.p`
  font-family: 'Exo 2', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
  text-align: center;

  @media (max-width: ${breakpoints.md}) {
    font-size: 18px;
  }
`;

const ContentColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  position: relative; // Added for absolute positioning of background
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: url(${aboutTextBgLogo}) no-repeat center center;
    background-size: contain;
    opacity: 0.1; // Very faded
    pointer-events: none;
    z-index: 0;
  }
  
  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    align-items: center;
  }
`;

const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
               0 0 20px rgba(76, 140, 47, 0.4),
               4px 4px 0 #8b4513;
  -webkit-text-stroke: 2px #4c8c2f;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, transparent, #8b4513, transparent);
    opacity: 0.8;
  }

  @media (max-width: ${breakpoints.md}) {
    -webkit-text-stroke: 1px #4c8c2f;
    text-align: center;
    margin-bottom: 1.5rem;
  }
`;

const Description = styled.p`
  position: relative;  
  z-index: 1;         
  font-family: 'Roboto', sans-serif;
  font-size: clamp(1rem, 1.2vw, 1.25rem);
  line-height: 1.6;
  color: #ffffff;
  margin-bottom: 1rem;
  background: linear-gradient(to right, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: ${props => props.textalign || 'left'};

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    font-size: 1rem;
  }
`;

const StaticCloud = styled.div`
  position: absolute;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  opacity: 0.8;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.4);
  z-index: -1;
  transform: translateZ(0);

  ${props => props.position === 'left' ? `
    top: 20%;
    left: -100px;
    @media (max-width: ${breakpoints.md}) {
      width: 150px;
      height: 150px;
      top: 10%;
      left: -50px;
    }
  ` : `
    bottom: 20%;
    right: -100px;
    @media (max-width: ${breakpoints.md}) {
      width: 180px;
      height: 180px;
      bottom: 10%;
      right: -50px;
    }
  `}
`;

const HighlightText = styled.span`
  color: #ffcc00;
  text-shadow: 0 0 5px rgba(255, 204, 0, 0.8);
  font-weight: 600;
`;

const About = () => {
  const textContent = [
    {
      id: 'mission',
      content: (
        <>
          We believe laughter is the best remedy, and our mission is to spread joy and break taboos about digestive health with humor and fun activities.
        </>
      )
    },
    {
      id: 'intro',
      content: (
        <>
          In FartyLand, you can:
        </>
      )
    },
    {
      id: 'game',
      content: (
        <>
          <HighlightText>Play the Farty Game</HighlightText>: Time your "toots" with passing cars, earn Farty Points, and exchange them for rewards.
        </>
      )
    },
    {
      id: 'share',
      content: (
        <>
          <HighlightText>Share Your Farts</HighlightText>: Upload your best moments (like Tiktok videos) in our Telegram Mini-app, where users can vote to reward the top "toots".
        </>
      )
    },
    {
      id: 'ai',
      content: (
        <>
          <HighlightText>Ask Farty</HighlightText>: Get tips and guidance from Farty, your Digestive Health AI Agent, covering gas, digestion, and healthy living.
        </>
      )
    },
    {
      id: 'conclusion',
      content: (
        <>
          Every toot in FartyLand spreads laughter, rewards creativity, and promotes healthier living. Let's Farty!
        </>
      )
    }
  ];

  return (
    <AboutContainer id="about">
      <ContentWrapper>
        <ImageColumn>
          <LogoContainer>
            <Logo 
              src={aboutLogo} 
              alt="Farty Character"
              loading="lazy"
            />
            <LogoOverlay>
              <LogoText>Frens I am to the moon</LogoText>
            </LogoOverlay>
          </LogoContainer>
        </ImageColumn>

        <ContentColumn>
          <Title>About $FARTY</Title>
          {textContent.map((item) => (
            <Description key={item.id} textalign="left">
              {item.content}
            </Description>
          ))}
        </ContentColumn>
      </ContentWrapper>

      <StaticCloud size={200} position="left" />
      <StaticCloud size={250} position="right" />
    </AboutContainer>
  );
};

export default About 