import React from 'react';
import styled from 'styled-components';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 650px;
  margin-top: -2rem; 

  @media (max-width: 768px) {
    margin-top: 0; 
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  margin-bottom: 1.5rem;
  position: relative;
`;

const TitleLine = styled.h1`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(2.5rem, 4vw, 4.2rem);
  font-weight: 800;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.1;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 
               0 0 20px rgba(76, 140, 47, 0.4),
               4px 4px 0 #8b4513;
  -webkit-text-stroke: 2px #4c8c2f;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    text-align: center;
    -webkit-text-stroke: 1px #4c8c2f;
  }
`;

const TitleUnderline = styled.div`
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, transparent, #8b4513, transparent);
  opacity: 0.8;
  margin-top: 0.75rem;
`;

const MainContent = styled.div`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(1.2rem, 1.5vw, 1.6rem);
  line-height: 1.6;
  color: #ffffff;
  margin: 1.2rem 0;
  max-width: 600px;
  letter-spacing: 0.02em;
  font-weight: 500;

  .highlight {
    color: #FFD700;
    font-weight: 700;
    text-shadow: 0 0 5px rgba(255, 204, 0, 0.4);
    position: relative;
    display: inline-block;
    padding: 0 0.1em;

    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 2px; // Adjusted to start from 'e'
      width: calc(100% - 4px); // Adjusted width to match text perfectly
      height: 2px;
      background: rgba(255, 215, 0, 0.3);
    }
  }
`;

const LetsFarty = styled.div`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(1.8rem, 2.5vw, 2.8rem);
  font-weight: 800;
  color: #FFD700;
  text-shadow: 0 0 10px rgba(255, 204, 0, 0.4),
               2px 2px 0 rgba(139, 69, 19, 0.8);
  letter-spacing: 0.05em;
  margin: 0.75rem 0 1.5rem;
  position: relative;
  width: fit-content; // Added to make container match text width

  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%; // Now it will only cover the text width
    height: 2px;
    background: rgba(255, 215, 0, 0.4);
  }

  @media (max-width: 768px) {
    margin: 0.75rem auto 1.5rem;
    text-align: center;
  }
`;




const HeroContent = () => {
  return (
    <ContentContainer>
      <TitleContainer>
        <TitleLine>FARTY IN</TitleLine>
        <TitleLine>FARTYLAND</TitleLine>
        <TitleUnderline />
      </TitleContainer>

      <MainContent>
        <span className="highlight">Ever held in a fart and died inside?</span> Those days are 
        OVER! Life's too short to hold it in, and <span className="highlight">FARTY</span> is turning 
        nature's calls into crypto's most explosive gains. No shame, all moon – just pure, 
        natural energy! Ready to let it rip?
      </MainContent>

      <LetsFarty>LET'S FARTY!</LetsFarty>

      
    </ContentContainer>
  );
};

export default HeroContent;
