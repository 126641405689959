import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import WhitePaperLayout from './components/layout/WhitePaperLayout'
import Introduction from './components/sections/Introduction'
import Mission from './components/sections/Mission'
import Features from './components/sections/Features'
import Vision from './components/sections/Vision'
import Taglines from './components/sections/Taglines'
import Tokenomics from './components/sections/Tokenomics'
import Roadmap from './components/sections/Roadmap'
import Conclusion from './components/sections/Conclusion'

const StandaloneContainer = styled.div`
	min-height: 100vh;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	/* iOS height fix */
	@supports (-webkit-touch-callout: none) {
		min-height: -webkit-fill-available;
	}
`

const WhitePaper = () => {
	const containerRef = useRef(null)
	const [scrollPosition, setScrollPosition] = useState(0)
	const [totalHeight, setTotalHeight] = useState(0)

	useEffect(() => {
		const handleScroll = () => {
			const height = Math.max(document.documentElement.scrollHeight, document.documentElement.offsetHeight) - window.innerHeight
			setTotalHeight(height)
			if (containerRef.current) {
				updateHeight()
				const position = containerRef.current.scrollTop
				setScrollPosition(position)
			}
		}

		const updateHeight = () => {
			if (containerRef.current) {
				const height = containerRef.current.scrollHeight - window.innerHeight

				setTotalHeight(height)
			}
		}
		const container = containerRef.current
		if (container) {
			container.addEventListener('scroll', handleScroll, {passive: true})
		}
		updateHeight()
		return () => {
			if (container) {
				container.removeEventListener('scroll', handleScroll)
			}
		}
	}, [])

	return (
		<StandaloneContainer ref={containerRef}>
			<WhitePaperLayout scrollPosition={scrollPosition} height={totalHeight}>
				<Introduction />
				<Mission />
				<Features />
				<Vision />
				<Taglines />
				<Tokenomics />
				<Roadmap />
				<Conclusion />
			</WhitePaperLayout>
		</StandaloneContainer>
	)
}

export default WhitePaper
