import React from 'react'
import styled from 'styled-components'
import {Eye} from 'lucide-react'

const Section = styled.section`
	padding: 2rem;
	background: rgba(76, 140, 47, 0.1);
	backdrop-filter: blur(10px);
	border-radius: 1rem;
	border: 1px solid rgba(76, 140, 47, 0.2);
	margin-bottom: 2rem;
`

const Title = styled.h2`
	font-family: 'Exo 2', sans-serif;
	font-size: clamp(2rem, 3vw, 2.5rem);
	font-weight: 800;
	color: #ffffff;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	line-height: 1.2;
	text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(76, 140, 47, 0.4), 4px 4px 0 #8b4513;
	-webkit-text-stroke: 1px #4c8c2f;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	gap: 1rem;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: -0.5rem;
		left: 0;
		width: 100px;
		height: 4px;
		background: linear-gradient(to right, #8b4513, transparent);
	}
	@media (max-width: 768px) {
		font-size: clamp(1.2rem, 3vw, 2.5rem);
	}
`

const IconWrapper = styled.div`
	width: 50px;
	height: 50px;
	background: rgba(76, 140, 47, 0.3);
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		color: #ffffff;
		width: 30px;
		height: 30px;
	}
	@media (max-width: 768px) {
		width: 40px;
		height: 40px;
		svg {
			color: #ffffff;
			width: 20px;
			height: 20px;
		}
	}
`

const Content = styled.div`
	font-family: 'Roboto', sans-serif;
	font-size: clamp(1.1rem, 1.2vw, 1.25rem);
	line-height: 1.8;
	color: #ffffff;
	background: linear-gradient(to right, #ffffff, #e0e0e0);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
`

const VisionCard = styled.div`
	background: rgba(76, 140, 47, 0.15);
	border-radius: 1rem;
	padding: 2rem;
	border: 1px solid rgba(76, 140, 47, 0.3);
	margin-top: 1.5rem;
	transition: transform 0.2s ease, box-shadow 0.2s ease;

	&:hover {
		transform: translateY(-5px);
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	}
`

const Highlight = styled.span`
	color: #ffd700;
	font-weight: 700;
	text-shadow: 0 0 5px rgba(255, 204, 0, 0.4);
	-webkit-text-fill-color: #ffd700;
	position: relative;
	display: inline-block;
	padding: 0 0.1em;

	&::after {
		content: '';
		position: absolute;
		bottom: -2px;
		left: 0;
		width: 100%;
		height: 2px;
		background: rgba(255, 215, 0, 0.3);
	}
`

const Vision = () => {
	return (
		<Section id="vision">
			<Title>
				<IconWrapper>
					<Eye />
				</IconWrapper>
				Vision
			</Title>

			<VisionCard>
				<Content>
					<p>
						Our vision is to create a world where no one feels ashamed of a natural process. By integrating <Highlight>blockchain technology</Highlight> with entertainment and education, Farty aims to redefine how people perceive and
						discuss digestive health.
					</p>
					<p>
						We envision <Highlight>Farty</Highlight> as a global phenomenon that inspires laughter, acceptance, and improved well-being. Through our innovative approach, we're not just creating a token – we're fostering a movement that
						combines humor with health awareness.
					</p>
					<p>
						By leveraging the power of community, technology, and education, we're building a future where digestive health can be discussed openly, where laughter is medicine, and where every <Highlight>toot</Highlight> is a step towards
						breaking down societal taboos.
					</p>
				</Content>
			</VisionCard>
		</Section>
	)
}

export default Vision
