import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Import assets
import TwitterIcon from '../assets/Twitter.png';
import TelegramIcon from '../assets/Send.png';
import logo from "../assets/footerlogo.webp";

const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

const FooterContainer = styled.footer`
  position: relative;
  padding: 2rem 1rem;
  background: rgba(76, 140, 47, 0.1);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;

  ${media.md} {
    padding: 2.5rem 1.5rem;
  }

  ${media.lg} {
    padding: 3rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    text-align: center;
    gap: 1.5rem;
  }
`;

const LogoWrapper = styled(motion.div)`
  flex-shrink: 0;
`;

const LogoImage = styled.img`
  width: 120px;
  height: auto;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));

  ${media.md} {
    width: 150px;
  }
`;

const CopyrightText = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: #ffffff;
  line-height: 1.5;
  margin: 0;
  background: linear-gradient(to right, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${media.md} {
    font-size: 1.125rem;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const SocialLink = styled(motion.a)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  transition: background 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.2)
    );
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.2);

    &::before {
      opacity: 1;
    }
  }

  ${media.md} {
    width: 50px;
    height: 50px;
  }
`;

const SocialIcon = styled.img`
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 1;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));

  ${media.md} {
    width: 28px;
    height: 28px;
  }
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: "easeOut",
        delayChildren: 0.2,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20 
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const socialLinkVariants = {
    hover: {
      scale: 1.1,
      rotate: 5,
      transition: {
        duration: 0.2,
        ease: "easeInOut"
      }
    },
    tap: {
      scale: 0.95,
      rotate: 0
    }
  };

  const socialLinks = [
    {
      platform: "Twitter",
      url: "https://x.com/fartyland",
      icon: TwitterIcon,
      alt: "Follow us on Twitter"
    },
    {
      platform: "Telegram",
      url: "https://t.me/fartylandofficial",
      icon: TelegramIcon,
      alt: "Join our Telegram"
    }
  ];

  return (
    <FooterContainer>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <ContentWrapper>
          {/* Logo Section */}
          <LogoWrapper variants={itemVariants}>
            <LogoImage 
              src={logo} 
              alt="Farty Logo"
              loading="lazy"
            />
          </LogoWrapper>

          {/* Copyright Section */}
          <motion.div variants={itemVariants}>
            <CopyrightText>
              &copy; {currentYear} FartyLand. All rights reserved.
            </CopyrightText>
          </motion.div>

          {/* Social Links Section */}
          <SocialLinks>
            {socialLinks.map((link, index) => (
              <motion.div key={link.platform} variants={itemVariants}>
                <SocialLink
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={link.alt}
                  variants={socialLinkVariants}
                  whileHover="hover"
                  whileTap="tap"
                >
                  <SocialIcon 
                    src={link.icon} 
                    alt={link.platform}
                    loading="lazy"
                  />
                </SocialLink>
              </motion.div>
            ))}
          </SocialLinks>
        </ContentWrapper>

      
      
      </motion.div>
    </FooterContainer>
  );
};



export default Footer;