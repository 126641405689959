import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

// Main logo and action button logos
import mainLogo from '../../assets/hero.png';
import engineerLogo from '../../assets/memecoinengineergreen.png';
import basketballLogo from '../../assets/memecoinbasketballgreen.png';
import doctorLogo from '../../assets/memecoindoctorgreen.png';

// Social and chart logos
import telegramLogo from '../../assets/Telegram.png';
import xLogo from '../../assets/Twitter1.png';
import dexscreenerLogo from '../../assets/header/dexscreener.webp';
import dexviewLogo from '../../assets/header/coingeco.png';
import dextoolsLogo from '../../assets/header/dex.webp';
import cmcLogo from '../../assets/header/coinmarketcap.webp';

const bubbleGlow = keyframes`
  0% {
    box-shadow: 0 0 20px rgba(76, 140, 47, 0.3);
  }
  50% {
    box-shadow: 0 0 30px rgba(76, 140, 47, 0.5), 0 0 10px rgba(255, 255, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 20px rgba(76, 140, 47, 0.3);
  }
`;

// Main container
const DisplayContainer = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin-top: -2rem;

  @media (max-width: 768px) {
    height: 550px;
    padding: 1rem;
    margin-top: 0;
  }
`;

// Main logo container with crystal effect
const MainLogoContainer = styled(motion.div)`
  position: relative;
  width: 80%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-radius: 50%;
  background: rgba(76, 140, 47, 0.3);
  padding: 2rem;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(76, 140, 47, 0.2) 50%,
      rgba(0, 0, 0, 0.1) 100%
    );
    border-radius: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 1px;
    border-radius: 50%;
    box-shadow: 
      inset 0 0 30px rgba(76, 140, 47, 0.3),
      inset 0 0 60px rgba(76, 140, 47, 0.2),
      0 0 30px rgba(76, 140, 47, 0.4);
  }

  @media (max-width: 768px) {
    width: 55%;
    padding: 1.5rem;
  }
`;

const MainLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0 0 15px rgba(76, 140, 47, 0.4));
  transition: filter 0.3s ease;
  position: relative;
  z-index: 2;

  &:hover {
    filter: drop-shadow(0 0 20px rgba(76, 140, 47, 0.6));
  }
`;

const MainLogoCrystalEffect = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    135deg,
    rgba(76, 140, 47, 0.2) 0%,
    rgba(76, 140, 47, 0.1) 50%,
    rgba(0, 0, 0, 0.05) 100%
  );
  border-radius: 50%;
  z-index: 1;
`;
// Action button styling and effects
const ActionButton = styled.a`
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: 'Exo 2', sans-serif;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2;
  padding: 1rem;
  transition: all 0.3s ease;
  z-index: 3;
  overflow: hidden;
  
  background-color: ${({ $variant }) => 
    $variant === 'primary' ? '#8b4513' :
    $variant === 'secondary' ? '#4b2c20' :
    '#4c8c2f'};

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.45) 0%,
      rgba(255, 255, 255, 0.12) 100%
    );
    border-radius: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 1px;
    border-radius: 50%;
    box-shadow: 
      inset 0 0 15px rgba(0, 0, 0, 0.5),
      inset 0 0 4px rgba(255, 255, 255, 0.3);
  }

  // Positioning for main action buttons
  &:nth-of-type(1) { // Buy button
    top: 6%;
    left: 35%;
  }

  &:nth-of-type(2) { // Charts button
    bottom: 12%;
    left: 8%;
  }

  &:nth-of-type(3) { // Play button
    bottom: 12%;
    right: 8%;
  }

  span {
    position: relative;
    z-index: 2;
    font-size: 0.85rem;
    text-shadow: 
      0 2px 4px rgba(0, 0, 0, 0.3),
      0 0 6px rgba(255, 255, 255, 0.4);
  }

  &:hover {
    transform: scale(1.05) translateY(-2px);
    box-shadow: 
      0 10px 20px rgba(0, 0, 0, 0.2),
      0 0 20px rgba(255, 255, 255, 0.15);
  }

  @media (max-width: 768px) {
    width: 85px;
    height: 85px;
    font-size: 0.75rem;

    &:nth-of-type(1) {
      top: 14%;
      left: 32%;
    }

    &:nth-of-type(2) {
      bottom: 30%;
      left: -4%;
    }

    &:nth-of-type(3) {
      bottom: 30%;
      right: -4%;
    }
  }
`;

const ButtonBackground = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 50%;
  overflow: hidden;
  opacity: 0.15;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: brightness(1.5);
    transform: scale(0.8);
  }
`;

const CrystalEffect = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.08) 50%,
    rgba(0, 0, 0, 0.08) 100%
  );
`;

const SmallBubble = styled(motion.a)`
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(76, 140, 47, 0.9);
  transition: all 0.3s ease;
  z-index: 2;
  overflow: hidden;
  animation: ${bubbleGlow} 2s ease-in-out infinite;

  ${({ position }) => position && `
    top: ${position.top};
    left: ${position.left};
    right: ${position.right};
    bottom: ${position.bottom};
  `}

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      20deg,
      rgba(255, 255, 255, 0.07) 40%,
      rgb(149, 89, 20) 100%
    );
    border-radius: 20%;
    z-index: -1;
  }

  img {
    width: 70%;
    height: 70%;
    object-fit: contain;
    filter: brightness(0.7) drop-shadow(0 0 5px rgba(255, 255, 255, 0.9));
    transition: transform 0.3s ease;
  }

  &:hover {
    transform: scale(1.15);
    background: rgba(76, 140, 47, 0.6);

    img {
      transform: scale(1.1);
    }
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;

    ${({ mobilePosition }) => mobilePosition && `
      top: ${mobilePosition.top};
      left: ${mobilePosition.left};
      right: ${mobilePosition.right};
      bottom: ${mobilePosition.bottom};
    `}
  }
`;

// Configuration data for bubbles
const actionButtons = [
  {
    label: 'Buy $FARTY',
    variant: 'primary',
    href: 'https://raydium.io/swap/?inputCurrency=sol&outputCurrency=7fXUVDUqU2CDmijVU94SF2k4XzS6TESaHnFra875qnaA&inputMint=5L5neHmTdmt3d8ao4x1iYx9ZUqm9ammrcr4AKML9pump&outputMint=sol',
    logo: engineerLogo
  },
  {
    label: 'View Charts',
    variant: 'secondary',
    href: 'https://dexscreener.com/solana/7fXUVDUqU2CDmijVU94SF2k4XzS6TESaHnFra875qnaA',
    logo: doctorLogo
  },
  {
    label: 'Play Game',
    variant: 'tertiary',
    href: '/game',
    logo: basketballLogo,
    isInternal: true
  }
];

// Update the socialBubbles array
const socialBubbles = [
  {
    position: { top: '30%', left: '10%' },
    mobilePosition: { top: '40%', left: '13%' },
    href: 'https://t.me/fartylandofficial',
    icon: telegramLogo
  },
  {
    position: { top: '45%', left: '5%' },
    mobilePosition: { top: '30%', left: '20%' },
    href: 'https://x.com/fartyland',
    icon: xLogo
  },
  {
    position: { top: '30%', right: '10%' },
    mobilePosition: { top: '30%', right: '20%' },
    href: 'https://coinmarketcap.com/dexscan/solana/7fXUVDUqU2CDmijVU94SF2k4XzS6TESaHnFra875qnaA',
    icon: cmcLogo
  },
  {
    position: { bottom: '45%', right: '5%' },
    mobilePosition: { bottom: '52%', right: '11%' },
    href: 'https://www.coingecko.com/en/coins/let-s-farty',
    icon: dexviewLogo
  },
  {
    position: { bottom: '12%', left: '33%' },
    mobilePosition: { bottom: '25%', left: '32%' },
    href: 'https://dexscreener.com/solana/7fXUVDUqU2CDmijVU94SF2k4XzS6TESaHnFra875qnaA',
    icon: dexscreenerLogo
   
  },
  {
    position: { bottom: '12%', right: '33%' },
    mobilePosition: { bottom: '25%', right: '32%' },
    href: 'https://www.dextools.io/app/solana/pair-explorer/7fXUVDUqU2CDmijVU94SF2k4XzS6TESaHnFra875qnaA',
    icon: dextoolsLogo
    
  }
];


const LogoOrbitDisplay = () => {
  return (
    <DisplayContainer>
      <MainLogoContainer
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <MainLogoCrystalEffect />
        <MainLogo src={mainLogo} alt="Main Logo" />
      </MainLogoContainer>

      {actionButtons.map((button, index) => (
        button.isInternal ? (
          <ActionButton
            key={`button-${index}`}
            as={Link}
            to={button.href}
            $variant={button.variant}
          >
            <ButtonBackground>
              <img src={button.logo} alt={`${button.label} Background`} />
            </ButtonBackground>
            <CrystalEffect />
            <span>{button.label}</span>
          </ActionButton>
        ) : (
          <ActionButton
            key={`button-${index}`}
            href={button.href}
            $variant={button.variant}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ButtonBackground>
              <img src={button.logo} alt={`${button.label} Background`} />
            </ButtonBackground>
            <CrystalEffect />
            <span>{button.label}</span>
          </ActionButton>
        )
      ))}

{socialBubbles.map((bubble, index) => (
        <SmallBubble
          key={`social-${index}`}
          href={bubble.href}
          target="_blank"
          rel="noopener noreferrer"
          position={bubble.position}
          mobilePosition={bubble.mobilePosition}
          whileHover={{ scale: 1.15 }}
          whileTap={{ scale: 0.9 }}
        >
          <img src={bubble.icon} alt="Social Link" />
        </SmallBubble>
      ))}
    </DisplayContainer>
  );
};

export default LogoOrbitDisplay;