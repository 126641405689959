import React from 'react'
import styled from 'styled-components'

import {ExternalLink, Globe, Menu, X} from 'lucide-react'
import {BsArrowUpRight, BsTelegram, BsTwitter, BsTwitterX, BsX} from 'react-icons/bs'
import {BiGlobe} from 'react-icons/bi'

import {CgExternal} from 'react-icons/cg'
const SidebarWrapper = styled.aside`
	background: rgba(76, 140, 47, 0.15);
	backdrop-filter: blur(10px);
	border-right: 1px solid rgba(76, 140, 47, 0.3);
	height: 100vh;
	position: fixed;
	width: 300px;
	z-index: 50;
	overflow-y: auto;
	transform: translateX(${(props) => (props.$isOpen ? '0' : '-100%')});
	transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);

	@media (min-width: 992px) {
		box-shadow: 4px 0 15px rgba(0, 0, 0, 0.1);
	}

	@media (max-width: 991px) {
		width: 280px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
	}

	/* Improved scrollbar */
	scrollbar-width: thin;
	scrollbar-color: rgba(76, 140, 47, 0.5) rgba(255, 255, 255, 0.1);

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: rgba(255, 255, 255, 0.1);
	}

	&::-webkit-scrollbar-thumb {
		background: rgba(76, 140, 47, 0.5);
		border-radius: 2px;

		&:hover {
			background: rgba(76, 140, 47, 0.7);
		}
	}
`

const NavList = styled.ul`
	list-style: none;
	padding: 2rem 1rem;
	margin: 0;
`

const NavItem = styled.li`
	margin: 0.5rem 0;
`

const NavLink = styled.a`
	font-family: 'Exo 2', sans-serif;
	font-size: 0.9375rem;
	color: ${(props) => (props.$active ? '#ffffff' : 'rgba(255, 255, 255, 0.8)')};
	text-decoration: none;
	padding: 0.75rem 1rem;
	border-radius: 8px;
	display: flex;
	align-items: center;
	position: relative;
	transition: all 0.2s ease;
	cursor: pointer;
	background: ${(props) => (props.$active ? 'rgba(76, 140, 47, 0.3)' : 'transparent')};
	font-weight: ${(props) => (props.$active ? '600' : '400')};

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 3px;
		height: ${(props) => (props.$active ? '70%' : '0%')};
		background: #4c8c2f;
		border-radius: 2px;
		transition: height 0.2s ease;
	}

	&:hover {
		background: rgba(76, 140, 47, 0.2);
		color: #ffffff;
		transform: translateX(3px);

		&::before {
			height: ${(props) => (props.$active ? '70%' : '40%')};
		}
	}
`

const MenuButton = styled.button`
	background: none;
	border: none;
	width: 40px;
	height: 40px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: flex-end;
	cursor: pointer;
	color: #ffffff;
	transition: all 0.2s ease;
	position: relative;
	overflow: hidden;

	&:hover {
		background: rgba(255, 255, 255, 0.1);
	}

	&:active {
		transform: scale(0.95);
	}

	/* Ripple effect on click */
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.2);
		border-radius: 50%;
		transform: scale(0);
		transition: transform 0.3s ease-out;
	}

	&:active::after {
		transform: scale(2);
		opacity: 0;
	}
`

const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: flex-end;
	transition: transform 0.3s ease;
	transform: ${(props) => (props.$isOpen ? 'rotate(180deg)' : 'rotate(0)')};
`

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	@media (min-width: 992px) {
		display: none;
	}
`

const LinkDIv = styled.div`
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
	/* width: 100%; */
	& > span {
		display: flex;
		align-items: center;
	}
`

const Sidebar = ({isOpen, activeSection, onNavigate, navigationItems, onToggle}) => {
	const iconMap = {
		telegram: <BsTelegram fontSize={'1.3rem'} color="white" />,
		telegram_mini_app: <BsTelegram fontSize={'1.3rem'} color="white" />,
		x: <BsTwitterX fontSize={'1.3rem'} color="white" />
	}

	const handleLinkClick = (e, item) => {
		// Check if it's an external link
		if (item.url) {
			window.open(item.url, '_blank', 'noopener noreferrer')
		} else {
			e.preventDefault()
			const element = document.getElementById(item.id)
			if (element) {
				element.scrollIntoView({behavior: 'smooth'})
			}
			if (onNavigate) {
				onNavigate(item.id)
			}
		}
	}

	return (
		<SidebarWrapper $isOpen={isOpen}>
			<IconContainer>
				<MenuButton onClick={onToggle} aria-label={isOpen ? 'Close menu' : 'Open menu'} aria-expanded={isOpen}>
					<IconWrapper $isOpen={isOpen}>{isOpen ? <X size={24} /> : <Menu size={24} />}</IconWrapper>
				</MenuButton>
			</IconContainer>

			<NavList role="navigation">
				{navigationItems.map((item) => (
					<NavItem key={item.id}>
						<NavLink
							href={item?.url || `#${item.id}`}
							target={item?.url ? '_blank' : '_self'}
							rel={item?.url ? 'noopener noreferrer' : undefined}
							onClick={(e) => {
								e.preventDefault()
								handleLinkClick(e, item)
							}}
							$active={activeSection === item.id}
							role="button"
							tabIndex={0}>
							{item.id in iconMap ? (
								<LinkDIv>
									{iconMap[item.id]} &nbsp; &nbsp;
									{item.label} &nbsp;&nbsp;
									<CgExternal fontSize={'1.3rem'} color="white" />
								</LinkDIv>
							) : (
								item.label
							)}
						</NavLink>
					</NavItem>
				))}
			</NavList>
		</SidebarWrapper>
	)
}

export default Sidebar
