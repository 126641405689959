import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import webvideo from "../assets/farti.gif";
import doctorLogo from "../assets/memecoindoctorgreen.png";
import fartLogo from "../assets/memecoinengineergreen.png";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const fadeInOut = keyframes`
  0% { opacity: 0; transform: translateY(20px); }
  20% { opacity: 1; transform: translateY(0); }
  80% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-20px); }
`;

const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};
const notificationAnimation = keyframes`
  0% { transform: translate(-50%, 10px); opacity: 0; scale: 0.9; }
  20% { transform: translate(-50%, 0); opacity: 1; scale: 1.05; }
  35% { transform: translate(-50%, 0); scale: 1; }
  85% { transform: translate(-50%, 0); opacity: 1; }
  100% { transform: translate(-50%, -10px); opacity: 0; }
`;


const CTAContainer = styled.section`
  position: relative;
  padding: 4rem 1rem;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;

  @keyframes fadeIn {
    to { opacity: 1; }
  }

  ${media.md} {
    padding: 5rem 1.5rem;
  }

  ${media.lg} {
    padding: 6rem 2rem;
  }
`;

const BackgroundLogo = styled.div`
  position: absolute;
  width: 400px;
  height: 400px;
  background: url(${props => props.$logoSrc}) no-repeat center center;
  background-size: contain;
  opacity: 0.15;
  pointer-events: none;
  
  ${props => props.$position === 'left' ? `
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(15deg);
  ` : `
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(15deg);
  `}

  @media (max-width: 1200px) {
    width: 300px;
    height: 300px;
  }

  @media (max-width: 768px) {
    width: 180px;
    height: 180px;
    opacity: 0.15;
    
    ${props => props.$position === 'left' ? `
      bottom: 2%;
      right: -10px;
      transform: rotate(20deg);
    ` : `
      top: 4%;
      right: -10px;
      transform: rotate(-15deg);
    `}
  }
`;
const VideoContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  
  ${media.md} {
    max-width: 600px;
  }
`;

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  display: block;
  border-radius: 12px;
  background: transparent;
  mix-blend-mode: screen; 
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  
  @media (max-width: 768px) {
    mix-blend-mode: normal; 
  }
`;
const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  text-align: center;
`;

const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2;
  text-align: center;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
               0 0 20px rgba(76, 140, 47, 0.4),
               4px 4px 0 #8b4513;
  -webkit-text-stroke: 2px #4c8c2f;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 4px;
    background: linear-gradient(to right, transparent, #8b4513, transparent);
    opacity: 0.8;
  }
`;

const Description = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: clamp(1rem, 1.2vw, 1.25rem);
  line-height: 1.6;
  color: #ffffff;
  max-width: 800px;
  margin: 0 auto;
  background: linear-gradient(to right, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

const CTAButton = styled.button`
  font-family: 'Exo 2', sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  color: #ffffff;
  background: #4c8c2f;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 2.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 2rem 0;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.3s;
  }

  &:hover {
    background: #3a6b24;
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);

    &::before {
      left: 100%;
    }
  }

  &:active {
    transform: translateY(0);
  }
`;

const ContractAddress = styled.div`
  margin: 1rem auto;
  padding: 1.5rem;
  background: rgba(76, 140, 47, 0.1);
  border-radius: 0.75rem;
  max-width: 600px;
  width: 100%;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    background: rgba(76, 140, 47, 0.15);
  }

  .header {
    color: #ffffff;
    font-size: 1rem;
    margin-bottom: 0.75rem;
    font-weight: 600;
  }

  .info {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 1rem;
    color: #ffcc00;
    font-size: 0.9rem;
    text-shadow: 0 0 5px rgba(255, 204, 0, 0.3);

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  code {
    display: block;
    padding: 0.75rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    color: #ffcc00;
    font-family: monospace;
    font-size: 0.85rem;
    cursor: pointer;
    word-break: break-all;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(0, 0, 0, 0.3);
      transform: translateY(-1px);
    }

    &:active {
      transform: scale(0.98);
      background: rgba(0, 0, 0, 0.4);
    }
  }

  @media (max-width: 768px) {
    padding: 1rem;
    
    code {
      font-size: 0.75rem;
      padding: 0.5rem;
    }
  }
`;
const NotificationPopup = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  background: rgba(76, 140, 47, 0.4);
  color: white;
  padding: 0.8rem 2rem;
  border-radius: 999px;
  box-shadow: 0 4px 20px rgba(76, 140, 47, 0.2);
  animation: ${notificationAnimation} 2s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  z-index: 100;
  pointer-events: none;
  border: 1px solid rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(8px);
  font-family: 'Exo 2', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 0.9rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 1px;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.1)
    );
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
`;



const ImageContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  
  ${media.md} {
    max-width: 600px;
  }
`;

const GifImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const CTASection = () => {
  const [showNotification, setShowNotification] = useState(false);
  const contractAddress = "5L5neHmTdmt3d8ao4x1iYx9ZUqm9ammrcr4AKML9pump";

  const handleBuyClick = () => {
    window.open(`https://raydium.io/swap/?inputCurrency=sol&outputCurrency=7fXUVDUqU2CDmijVU94SF2k4XzS6TESaHnFra875qnaA&inputMint=5L5neHmTdmt3d8ao4x1iYx9ZUqm9ammrcr4AKML9pump&outputMint=sol`, '_blank', 'noopener,noreferrer');
  };

  const copyAddress = async () => {
    try {
      await navigator.clipboard.writeText(contractAddress);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <CTAContainer id="join-now">
      <BackgroundLogo $position="left" $logoSrc={doctorLogo}  />
      <BackgroundLogo $position="right" $logoSrc={fartLogo}  />
      
      <ContentWrapper>
        <div>
          <Title>Join the Fart Revolution!</Title>
          <Description>
            Don't miss to be a part of the most hilarious 
            and unique meme project ever created.
            <br /><br />
            Buy $FARTY now and let your farts be heard across the blockchain.
          </Description>
        </div>

        <CTAButton onClick={handleBuyClick}>
          Buy $FARTY Now!
        </CTAButton>

        <ContractAddress>
  <div className="header">Contract Address (click to copy):</div>
  <div className="info">
    <span>✅ Audit By Both of Us Fam</span>
    <span>🚀 Has 100% Gas to the moon</span>
  </div>
  <code onClick={copyAddress}>{contractAddress}</code>
  {showNotification && (
  <NotificationPopup>
    Copied! Let's FARTY 
  </NotificationPopup>
)}
</ContractAddress>
<ImageContainer>
  <GifImage src={webvideo} alt="Farty Animation" />
</ImageContainer>
      </ContentWrapper>

      {showNotification && (
        <NotificationPopup>
          Contract Address Copied! Let's FARTY!
        </NotificationPopup>
      )}
    </CTAContainer>
  );
};

export default CTASection;